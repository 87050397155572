// Core variables and mixins
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/variables";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/normalize";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/print";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/scaffolding";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/type";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/code";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/grid";
$spacer: 16px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
    0: (
        x: 0,
        y: 0
    ),
    1: (
        x: $spacer-x,
        y: $spacer-y
    ),
    2: (
        x: (
            $spacer-x * 1.5
        ),
        y: (
            $spacer-y * 1.5
        )
    ),
    3: (
        x: (
            $spacer-x * 3
        ),
        y: (
            $spacer-y * 3
        )
    )
) !default;

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
        $length-x: map-get($lengths, x);
        $length-y: map-get($lengths, y);

        .#{$abbrev}-a-#{$size} {
            #{$prop}: $length-y $length-x !important;
        } // a = All sides
        .#{$abbrev}-t-#{$size} {
            #{$prop}-top: $length-y !important;
        }
        .#{$abbrev}-r-#{$size} {
            #{$prop}-right: $length-x !important;
        }
        .#{$abbrev}-b-#{$size} {
            #{$prop}-bottom: $length-y !important;
        }
        .#{$abbrev}-l-#{$size} {
            #{$prop}-left: $length-x !important;
        }

        // Axes
        .#{$abbrev}-x-#{$size} {
            #{$prop}-right: $length-x !important;
            #{$prop}-left: $length-x !important;
        }
        .#{$abbrev}-y-#{$size} {
            #{$prop}-top: $length-y !important;
            #{$prop}-bottom: $length-y !important;
        }
    }
}

@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/tables";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/forms";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/component-animations";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/dropdowns";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/button-groups";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/input-groups";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/navs";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/navbar";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/pagination";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/pager";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/labels";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/badges";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/jumbotron";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/thumbnails";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/alerts";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/progress-bars";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/media";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/list-group";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/panels";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-embed";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/wells";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/modals";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/tooltip";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/popovers";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/utilities";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-utilities";
