ag-grid-ng2 {
  display: inline-block;
}
.ag-root {
  font-size: 14px;
  cursor: default;
/* set to relative, so absolute popups appear relative to this */
  position: relative;
/* disable user mouse selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
/* was getting some 'should be there' scrolls, this sorts it out */
  overflow: hidden;
}
.ag-no-scrolls {
  white-space: nowrap;
  display: inline-block;
}
.ag-scrolls {
  height: 100%;
}
.ag-popup-backdrop {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.ag-header {
  position: absolute;
  top: 0px;
  left: 0px;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}
.ag-pinned-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.ag-header-viewport {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.ag-scrolls .ag-header-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  height: 100%;
}
.ag-no-scrolls .ag-header-container {
  white-space: nowrap;
}
.ag-header-cell {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: bottom;
  text-align: center;
  display: inline-block;
}
.ag-header-cell-grouped {
  height: 50%;
}
.ag-header-cell-not-grouped {
  height: 100%;
}
.ag-header-group {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
}
.ag-header-group-cell {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  height: 50%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-header-group-cell-label {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-header-cell-label {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-header-cell-resize {
  height: 100%;
  width: 4px;
  float: right;
  cursor: col-resize;
}
.ag-header-cell-menu-button {
  float: right;
}
.ag-overlay-panel {
  display: table;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.ag-overlay-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ag-body {
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ag-floating-top {
  position: absolute;
  left: 0px;
  width: 100%;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.ag-pinned-floating-top {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.ag-floating-top-viewport {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.ag-floating-top-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}
.ag-floating-bottom {
  position: absolute;
  left: 0px;
  width: 100%;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.ag-pinned-floating-bottom {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.ag-floating-bottom-viewport {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}
.ag-floating-bottom-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}
.ag-pinned-cols-viewport {
  float: left;
  position: absolute;
  overflow: hidden;
}
.ag-pinned-cols-container {
  display: inline-block;
  position: relative;
}
.ag-body-viewport-wrapper {
  height: 100%;
}
.ag-body-viewport {
  overflow: auto;
  height: 100%;
}
.ag-scrolls .ag-body-container {
  position: relative;
  display: inline-block;
}
.ag-scrolls .ag-row {
  white-space: nowrap;
  position: absolute;
  width: 100%;
}
.agile-gird-row:hover {
  background-color: #f0f8ff;
}
.ag-cell {
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}
.ag-group-cell-entire-row {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-footer-cell-entire-row {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-large .ag-root {
  font-size: 20px;
}
.ag-filter {
  position: absolute;
  z-index: 100;
}
.ag-filter-list-viewport {
  overflow-x: auto;
  height: 200px;
  width: 200px;
}
.ag-filter-list-container {
  position: relative;
  overflow: hidden;
}
.ag-filter-item {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}
.ag-filter-filter {
  width: 170px;
  margin: 4px;
}
.ag-filter-select {
  width: 110px;
  margin: 4px 4px 0px 4px;
}
.ag-no-vertical-scroll .ag-scrolls {
  height: unset;
}
.ag-no-vertical-scroll .ag-body {
  height: unset;
}
.ag-no-vertical-scroll .ag-body-viewport-wrapper {
  height: unset;
}
.ag-no-vertical-scroll .ag-body-viewport {
  height: unset;
}
.ag-list-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.ag-tool-panel-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  width: 200px;
}
