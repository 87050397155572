@import "helpers";

.angular-wizard {
    position: relative;

    .steps-indicator {
        position: absolute;
        bottom: auto;
        top: 10px;

        li {
            &.editing {
                a {
                    font-weight: 600;
                }
            }
            &.current {
                a {
                    font-weight: 600;
                }
            }
        }
    }

    .steps {
        padding-top: 80px;
    }

    .steps-indicator li a {
        font-weight: 400;
        color: $dark-grey;
        text-transform: capitalize;

        &:hover {
            color: $black;
        }

        &:before {
            left: calc(46%);
        }
    }

    .wizard-navigation-buttons-container {
        margin: 15px 0;
        button {
            margin-left: 25px;
        }
    }
}
